import { Col, DatePicker, Drawer, Row, Space } from "antd";
import {
  setActiveHistoryTab,
  setHistoryEndDate,
  setHistoryStartDate,
  setHistoryDrawer,
} from "features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { useEffect,useState } from "react";
import EventHistory from "./EventHistory";
import BreathalyzerTestHistory from "./BreathalyzerHistory";
import MessageHistory from "./MessageHistory";
import NotesHistory from "./NotesHistory";
import GlossaryTermsDrawer from "./GlossaryTermsDrawer";
import GpsHistory from "./GpsHistory";
import styled from "styled-components";
import CamHistory from "./CamHistory";
import moment from "moment";
import ViolationHistory from "./ViolationHistory";
import MetricsChart from "./MetricsChart";
import { useParams } from "react-router-dom";
const { Option } = Select;
const { RangePicker } = DatePicker;

export const GlossaryButton = styled.button`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  color: #000;
  padding: 5px 16px;
  cursor: pointer;
`;

const HistoryDrawer = () => {
  const dispatch = useDispatch();
  const showDrawer = useSelector((state) => state.common.showHistoryDrawer);
  
  const activeHistoryTab = useSelector(
    (state) => state.common.activeHistoryTab
  );
  const [dates, setDates] = useState(null);
  const [isDateInitialized, setDateInitialized] = useState(false);
  const historyStartDate = useSelector((state) => state.common.historyStartDate);

  const { userData } = useSelector((state) => state.login);
  const { id,startDate,endDate } = useParams();
  const onClose = () => {
    if (activeHistoryTab === "glossary") {
      dispatch(setActiveHistoryTab("events"));
    } else {
      dispatch(setHistoryDrawer(false));
      dispatch(setActiveHistoryTab(""));
      dispatch(setHistoryStartDate(moment()));
    }
  };
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return !!tooEarly || !!tooLate;
  };
  const handleChange = (value) => {
    console.log(activeHistoryTab, "value here",value);
    if(value === "gpshistory"){
      console.log("gpshistory work hereee");
      setDates([moment().add(-1, 'days'),moment()]);
     // dispatch(setHistoryStartDate));
    }else{
      setDates([moment().add(-30, 'days'),moment()]);
    }
    dispatch(setActiveHistoryTab(value));
  };

  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

  const setStartDate = (value) => {
    console.log("work hereeeelllll");
    // console.log(value[0]["_i"]);
    console.log(value);
    if(value){
      setDates(value);
      value[0] ? dispatch(setHistoryStartDate(value[0])) : dispatch(setHistoryStartDate(null));
      value[1] ? dispatch(setHistoryEndDate(value[1])) : dispatch(setHistoryEndDate(null));
      
    } else {
      setDates(value);
      dispatch(setHistoryStartDate(null));
      dispatch(setHistoryEndDate(null));
    }
   
  }

  useEffect(() => {
    console.log("work here in param");
    let initialdate = false;
    if(startDate && endDate){
      console.log("work here in param no");
      setDateInitialized(true)
      initialdate = true;
    }
    if (!initialdate) {
      console.log("work here in param 2");
      setDateInitialized(true);
      let startDatene = moment().add(-30, 'days');
      //  console.log("nextDate",nextDate,"historyStartDate",historyStartDate);
      setDates([startDatene,moment()]);
      dispatch(setHistoryStartDate(startDatene));
      dispatch(setHistoryEndDate(moment()));
      setHistoryStartDate(startDatene)
      if(!historyStartDate){
        setHistoryStartDate(moment());
      }
    }else{
      setDates([moment(startDate),moment(endDate)]);
      dispatch(setHistoryStartDate(moment(startDate)));
      dispatch(setHistoryEndDate(moment(endDate)));
    //  setHistoryStartDate(moment(startDate))
      // if(!historyStartDate){
      //   setHistoryStartDate(moment());
      // }
    }
    
    if (!activeHistoryTab) {
      dispatch(setActiveHistoryTab("events"));
    }
    
  }, []);

  return (
    <Drawer
      destroyOnClose
      title={
        <Row>
          <Col span={8}>
            {"View History - " + participantProfileData?.fullName}
          </Col>
          <Col span={12}></Col>
          <Col span={4}>
            <GlossaryButton
              type="default"
              onClick={() => handleChange("glossary")}
            >
              Glossary of Terms
            </GlossaryButton>
          </Col>
        </Row>
      }
      _
      placement="right"
      size="large"
      onClose={onClose}
      visible={showDrawer}
    >
      <div className="container">
        {activeHistoryTab !== "glossary" && (
          <Row>
            <Col span={12}>
              <Select
                className="historyDropdown"
                defaultValue={activeHistoryTab}
                style={{ width: 280, height: 32 }}
                onChange={handleChange}
              >
                <Option value="gpshistory">TRAC GPS History</Option>
                <Option value="breathalyzer">TRAC Breath History</Option>
                <Option value="events">Event History</Option>
                {/* {userData.websiteUserType.isSuperUser && (
                  <Option value="cam">TRAC Watch Alcohol Reading History</Option>
                )} */}
                <Option value="violation">Violation History</Option>
                <Option value="notes">Notes History</Option>
                <Option value="message">Message History</Option>
                {userData.websiteUserType.isSuperUser ? (
                <Option value="metrics">TRAC Watch Metrics</Option>
                ):(<></>)}
                
              </Select>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "10px",
              }}
            >
              <Space direction="vertical" size={12}>
                {(activeHistoryTab === 'gpshistory' || activeHistoryTab === 'metrics') ? 
                  <RangePicker
                  value={dates}
                  showTime={{
                    format: 'HH:mm A', use12Hours: true
                  }}
                  format="MM-DD-YYYY HH:mm A"
                  showSecond={false}
                  minuteStep={30}
                  onCalendarChange={(v) => setStartDate(v)}
                  // disabledDate={disabledDate}
                 
                  style={{ width: 345, float: "right" }}
                /> : <RangePicker
                    value={dates}
                    format="MM-DD-YYYY"
                    onCalendarChange={(v) => setStartDate(v)}
                    // disabledDate={disabledDate}
                  
                    style={{ width: 280, float: "right" }}
                  />
                }
                
              </Space>
            </Col>
          </Row>
        )}

        {activeHistoryTab === "events" && <EventHistory />}
        {activeHistoryTab === "breathalyzer" && <BreathalyzerTestHistory />}
        {activeHistoryTab === "cam" && <CamHistory />}
        {activeHistoryTab === "notes" && <NotesHistory />}
        {activeHistoryTab === "message" && <MessageHistory />}
        {activeHistoryTab === "glossary" && <GlossaryTermsDrawer />}
        
        {activeHistoryTab === "gpshistory" && <GpsHistory />}
        {activeHistoryTab === "violation" && <ViolationHistory />}
        {activeHistoryTab === "metrics" && <MetricsChart />}
      </div>
    </Drawer>
  );
};

export default HistoryDrawer;
