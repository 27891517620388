import moment from "moment";
import { useEffect, useState,useRef } from "react";
import styled, { css } from "styled-components";
import { StyledButton } from "theme/StyledComponents";
import apiHandler from "api";
import { useSelector } from "react-redux";
import endpoint from "api/endpoint";
import DownloadTableButton from "components/DownloadTableButton/DownloadTableButton";
import { Col, Row,Button,notification,Modal } from "antd";
import zoomPlugin from 'chartjs-plugin-zoom';
import Logo from "assets/logo.png";
import InputAntd from "components/InputAntd/InputAntd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LineController
  // ChartOptions,
  // ChartData
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
import './MetricsChart.css';
import { option_1, option_2, option_3, option_4, option_5, option_6 } from "./MetricsChartConf";
import { isEmpty } from "lodash";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  zoomPlugin
);




export const StyledModal = styled(Modal)`
  border-radius: 10px;
`;


const MetricsChart = () => {
  const [zoomModalVisible, setZoomModalVisible] = useState(false);
  const [activeChart, setActiveChart] = useState(0);
  const { historyStartDate, historyEndDate } = useSelector((state) => state.common);

  const { authToken } = useSelector((state) => state.login);


  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );   

  let labels = [];
  const [deviceTypes, setAvailableDevices] = useState([]);

  // let chart_1_data = alcohalConcentrationData;
  // let chart_2_data = alarmsData;
  // let chart_3_data = touchVoltageData;
  // let chart_4_data = pressureData;
  // let chart_5_data = batteryPercentage;
  // let chart_6_data = phoneBatteryPercentage;
  const ct1 = useRef(null);
  const ct2 = useRef(null);
  const ct3 = useRef(null);
  const ct4 = useRef(null);
  const ct5 = useRef(null);
  const ct6 = useRef(null);
  const [chart1Drage,setChart1Drage]  = useState(false)
  const [selectionRect, setselectionRect]  = useState({
    w: 0,
    startX: 0,
    startY: 0
  })
  let selectionContext = null;
  const [startIndex, setStartIndex] = useState(0);
  const [chart_1_data, set_chart_1_data] = useState({});
  const [chart_2_data, set_chart_2_data] = useState({});
  const [chart_3_data, set_chart_3_data] = useState({});
  const [chart_4_data, set_chart_4_data] = useState({});
  const [chart_5_data, set_chart_5_data] = useState({});
  const [chart_6_data, set_chart_6_data] = useState({});

  const [show_chart_1, set_show_chart_1] = useState(false);
  const [show_chart_2, set_show_chart_2] = useState(false);
  const [show_chart_3, set_show_chart_3] = useState(false);
  const [show_chart_4, set_show_chart_4] = useState(false);
  const [show_chart_5, set_show_chart_5] = useState(false);
  const [show_chart_6, set_show_chart_6] = useState(false);
  const [csv_header, set_csv_header] = useState([]);
  const [csv_data, set_csv_data] = useState([]);
  const [canvasx, setCanvasX] = useState(0);
  const [canvasy, setCanvasY] = useState(0);
  const [lastMouseX, setLastMouseX] = useState(0);
  const [lastMouseY, setLastMouseY] = useState(0);
  const [mousex, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [mousedown, setMouseDown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventsType, setAvailableEvents ] = useState([]);
  const [chartType, setChartType] = useState("");
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [copyUrl, setCopyUrl] = useState("");
  const handleCancel = () => {
    setModalVisible(false);
    setCopyModalVisible(false);
    setZoomModalVisible(false);
  };
  let isDrag = false;
  useEffect(() => {
    apiHandler({
      url: `${endpoint.AVAILABLE_EVENT_TYPES}`,
      authToken,
    }).then((result) => {
      //const { eventsType } = result;
      setAvailableEvents(result.data)

      
    });
    if (historyStartDate && historyEndDate) {
      console.log(historyStartDate, historyEndDate);
      setChartShowToFalse();
      // console.log(historyStartDate.format("YYYY-MM-DDTHH:mmZ"), historyEndDate.format("YYYY-MM-DDTHH:mmZ"));
      apiHandler({
        url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/watchMetrics/" +(((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) || moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) + "/" +((typeof historyEndDate === 'string' || historyEndDate instanceof String )? historyEndDate:  historyEndDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")),
        authToken,
      }).then((result) => {
        console.log('======metrices data==11=====', result);
        if (result&&result.data) {
          assignChartData(result.data);
        }
      });
    } else if (historyStartDate) {
      setChartShowToFalse();
      apiHandler({
        url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/watchMetrics/" +(((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm:ssZ")) || moment().format("YYYY-MM-DDTHH:mm:ssZ")),
        authToken,
      }).then((result) => {
        console.log('======metrices data===22====', result);
        if (result&&result.data) {
          assignChartData(result.data);
        }
      });
    } else if (!historyStartDate) {
      // setChartShowToFalse();
      /* apiHandler({
        url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/watchMetrics/" +moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        authToken,
      }).then((result) => {
        console.log('======metrices data===33====', result);
        if (result&&result.data) {
          assignChartData(result.data);
        }
      }); */
    }
    getAvailableDeviceTypes();
  }, [historyStartDate, historyEndDate]);
  const getAvailableDeviceTypes = (agencyId) => {
    apiHandler({
      url: `${endpoint.AVAILABLE_DEVICES_AD}`,
      authToken,
    }).then((result) => {
      
      
      
      setAvailableDevices(result.data);
    });

  }

  const setChartShowToFalse = () => {
    set_show_chart_1(false);
    set_show_chart_2(false);
    set_show_chart_3(false);
    set_show_chart_4(false);
    set_show_chart_5(false);
    set_show_chart_6(false);
  }

  const assignChartData = (result) => {
    setChartShowToFalse();
    set_chart_1_data({}); 
    set_chart_2_data({}); 
    set_chart_3_data({}); 
    set_chart_4_data({}); 
    set_chart_5_data({}); 
    set_chart_6_data({}); 
    labels = [];

    set_show_chart_3(false);

    let chart_1_temp_data = [], chart_3_temp_data = [], chart_4_temp_data = [], chart_5_temp_data = [], chart_6_temp_data = [];
    
    if (result && result.length > 0) {

      const attributeKeys = Object.keys(result[0]);
      console.log(attributeKeys);
      const headers = attributeKeys.map(ky => ({label: ky, key: ky}));
      console.log(headers);

      set_csv_header(headers)
      set_csv_data(result);

      result.forEach(obj => {
        obj['evalDateTime'] = new moment(obj.evaluatedAt);
        obj['evalDateTimeF'] = obj['evalDateTime'].format("YYYY-MM-DD hh:mm a");
      });

      result.forEach(obj => {
        labels.push(obj['evalDateTimeF']);
        
        // for chart 1
        if (obj.alcoholConcentration !== null) {
          chart_1_temp_data.push(obj.alcoholConcentration);
        } else {
          chart_1_temp_data.push(0);
        }

        if (obj.alcoholConcentration !== null) {
          chart_1_temp_data.push(obj.alcoholConcentration);
        } else {
          chart_1_temp_data.push(0);
        }

        // for chart 3
        if (obj.touchVoltage !== null) {
          chart_3_temp_data.push(obj.touchVoltage);
        } else {
          chart_3_temp_data.push(2050);
        }

        // for chart 4
        if (obj.pressure !== null) {
          chart_4_temp_data.push(obj.pressure);
        } else {
          chart_4_temp_data.push(0);
        }

        // for chart 5
        if (obj.batteryPercentage !== null) {
          chart_5_temp_data.push(obj.batteryPercentage *100);
        } else {
          chart_5_temp_data.push(0);
        }

        // for chart 6
        if (obj.phoneBatteryPercentage !== null) {
          chart_6_temp_data.push(obj.phoneBatteryPercentage);
        } else {
          chart_6_temp_data.push(0);
        }

      });

      const green_line_idx = Math.round(labels.length * 30 /100);

      //create data for Charts

      //chart 1
      set_chart_1_data(getAlcohalConcentrationData(labels, chart_1_temp_data, green_line_idx));
      set_show_chart_1(true);

      //chart 2
      set_chart_2_data(getAlarmsData(labels, result, green_line_idx));
      set_show_chart_2(true);

      //chart 3
      set_chart_3_data(getTouchVoltageData(labels, chart_3_temp_data, green_line_idx));
      set_show_chart_3(true);

      //chart 4
      set_chart_4_data(getPressureData(labels, chart_4_temp_data, green_line_idx));
      set_show_chart_4(true);

      //chart 5
      set_chart_5_data(getBatteryPercentageData(labels, chart_5_temp_data, green_line_idx));
      set_show_chart_5(true);

      //chart 6
      set_chart_6_data(getPhoneBatteryPercentageData(labels, chart_6_temp_data, green_line_idx));
      set_show_chart_6(true);

    }
  }

  const getAlcohalConcentrationData = (labels, chart_1_temp_data, green_line_idx) => {
    const result = {
      labels,
      datasets: [
        {
          data: chart_1_temp_data,
          borderColor: "#4270c3",
          backgroundColor: "#4270c3",
          lineTension: 0.3
        }
      ]
    };

    let warning_line_1 = [], green_line = [];
    
    labels.forEach((lbl, idx) => {
      warning_line_1.push(0.05);
      if (idx === green_line_idx-1) {
        green_line.push(0.2);
      } else {
        green_line.push(0);
      }
    });
    
    // result.datasets.push({
    //   data: warning_line_1,
    //   borderColor: "#ee414f",
    //   backgroundColor: "#ee414f",
    //   fill: false,
    //   lineTension: 0.3,
    //   // type: "line",
    //   borderDash: [20, 15]
    // });


    /* result.datasets.push({
      type: 'bar',
      borderColor: '#1cc6bc',
      backgroundColor: "#1cc6bc",
      fill: false,
      data: green_line,
      maxBarThickness: 2,
    }); */

    return result;
  }

  const getAlarmsData = (labels, data, green_line_idx) => {
    const result = {
      labels,
      datasets: []
    };

    let alarmLowBattery = [], alarmTouch = [], alarmLowTouch = [], alarmPressure = [], alarmStrap = [], alarmTamper = [], alarmBlocked = [];
    data.forEach(obj => {
      if (obj.alarmLowBattery) {
        alarmLowBattery.push(1);
      } else {
        alarmLowBattery.push(0);
      }

      if (obj.alarmTouch) {
        alarmTouch.push(1);
      } else {
        alarmTouch.push(0);
      }

      if (obj.alarmLowTouch) {
        alarmLowTouch.push(1);
      } else {
        alarmLowTouch.push(0);
      }

      if (obj.alarmPressure) {
        alarmPressure.push(1);
      } else {
        alarmPressure.push(0);
      }

      if (obj.alarmStrap) {
        alarmStrap.push(1);
      } else {
        alarmStrap.push(0);
      }

      if (obj.alarmTamper) {
        alarmTamper.push(1);
      } else {
        alarmTamper.push(0);
      }

      if (obj.alarmBlocked) {
        alarmBlocked.push(1);
      } else {
        alarmBlocked.push(0);
      }
    });

    result.datasets.push({
      label: 'Low Battery',
      fille: true,
      data: alarmLowBattery,
      borderColor: "#3e6dc2",
      backgroundColor: "#3e6dc2",
      lineTension: 0.1,
      fill: true,
      order: 1
    });

    result.datasets.push({
      label: 'Non-Conductive Alert',
      fille: true,
      data: alarmTouch,
      borderColor: "#ed7d31",
      backgroundColor: "#ed7d31",
      lineTension: 0.1,
      fill: true,
      order: 1
    });

    result.datasets.push({
      label: 'Conductive Alert',
      data: alarmLowTouch,
      borderColor: "#a5a5a5",
      backgroundColor: "#a5a5a5",
      fill: true,
      lineTension: 0.1,
      order: 1
    });

    result.datasets.push({
      label: 'Pressure Alert',
      data: alarmPressure,
      borderColor: "#ffc000",
      backgroundColor: "#ffc000",
      fill: true,
      lineTension: 0.1,
      order: 1
    });

    result.datasets.push({
      label: 'Strap Open',
      data: alarmStrap,
      borderColor: "#5b9bd5",
      backgroundColor: "#5b9bd5",
      fill: true,
      lineTension: 0.1,
      order: 1
    });

    result.datasets.push({
      label: 'Case Open',
      data: alarmTamper,
      borderColor: "#264478",
      backgroundColor: "#264478",
      fill: true,
      lineTension: 0.1,
      order: 1
    });

    result.datasets.push({
      label: 'Sensor Blockage',
      data: alarmBlocked,
      borderColor: "#70ad47",
      backgroundColor: "#70ad47",
      fill: true,
      lineTension: 0.1,
      order: 1
    });

    return result;
  }

  const getTouchVoltageData = (labels, chart_3_temp_data, green_line_idx) => {
    const result = {
      labels,
      datasets: [
        {
          data: chart_3_temp_data,
          borderColor: "#4270c3",
          backgroundColor: "#4270c3",
          lineTension: 0.3
        }
      ]
    };

    let warning_line_1 = [], warning_line_2 = [], green_line = [];
    
    labels.forEach((lbl, idx) => {
      warning_line_1.push(3800);
      warning_line_2.push(600);
      if (idx === green_line_idx-1) {
        green_line.push(4500);
      } else {
        green_line.push(0);
      }
    });
    
    result.datasets.push({
      data: warning_line_1,
      borderColor: "#ffa467",
      backgroundColor: "#e9ffea",
      fill: 2,
      lineTension: 0.3,
      type: "line",
      borderDash: [20, 15],
      order: 2
    });

    result.datasets.push({
      data: warning_line_2,
      borderColor: "#ffa467",
      backgroundColor: "#ffa467",
      fill: false,
      lineTension: 0.3,
      type: "line",
      borderDash: [20, 15]
    });

    /* result.datasets.push({
      type: 'bar',
      borderColor: '#1cc6bc',
      backgroundColor: "#1cc6bc",
      fill: false,
      data: green_line,
      maxBarThickness: 2,
      order: 1
    }); */

    return result;
  }

  const getPressureData = (labels, chart_4_temp_data, green_line_idx) => {
    const result = {
      labels,
      datasets: [
        {
          data: chart_4_temp_data,
          borderColor: "#4270c3",
          backgroundColor: "#4270c3",
          lineTension: 0.3
        }
      ]
    };

    let warning_line_1 = [], green_line = [];
    
    labels.forEach((lbl, idx) => {
      warning_line_1.push(80);
      if (idx === green_line_idx-1) {
        green_line.push(120);
      } else {
        green_line.push(0);
      }
    });
    
    result.datasets.push({
      data: warning_line_1,
      borderColor: "#ee414f",
      backgroundColor: "#ee414f",
      fill: false,
      lineTension: 0.3,
      type: "line",
      borderDash: [20, 15]
    });


    /* result.datasets.push({
      type: 'bar',
      borderColor: '#1cc6bc',
      backgroundColor: "#1cc6bc",
      fill: false,
      data: green_line,
      maxBarThickness: 2,
    }); */

    return result;
  }
  const ponterUpOnChart = (e) =>{
    console.log("work here out",LineController);
    setMouseX(parseInt(e.clientX-canvasx));
    setLastMouseY(parseInt(e.clientY-canvasy));
      if(isDrag) {
        ct1.current.ctx.save();
        console.log("work here IN");
        ct1.current.ctx.clearRect(0,0,ct1.current.canvas.width,ct1.current.canvas.height); //clear canvas
          ct1.current.ctx.beginPath();
          var width = mousex-lastMouseX;
          var height = mouseY-lastMouseY;
          ct1.current.ctx.fillRect(lastMouseX,lastMouseY,width,height);
          ct1.current.ctx.strokeStyle = 'black';
          ct1.current.ctx.lineWidth = 10;
          ct1.current.ctx.stroke();
          ct1.current.ctx.restore();
      }
  
  }
  const pointerDownOnChart = (event)=>{
    setChartType("Chart1");
    console.log("chart1 data",chart_1_data);
  
    if (event.offsetX >= ct1.current.chartArea.left) {

      const datas = ct1.current.getElementsAtEventForMode(event, 'index', {
        intersect: false
      });
      if(datas.length>0){
      const start_y_value = datas[0].element.$context.parsed.y;
      // const start_y_value2 = datas[1].element.$context.parsed.y;
      // console.log("datas here",start_y_value2);
      const x_index = ct1.current.scales.x.getValueForPixel(event.offsetX);
      const start_label = ct1.current.data.labels[x_index];
  
   console.log(start_label + ' ' + start_y_value);
   setEventStartDate(start_label);
      }
  
    }
  }


const pointerOnChart = (event)=>{
  setChartType("Chart1");
  if (event.offsetX <=  ct1.current.chartArea.right) {

		const datas =  ct1.current.getElementsAtEventForMode(event, 'index', {
			intersect: false
		});
    if(datas.length>0){
		const end_y_value = datas[0].element.$context.parsed.y;
    //const end_y_value2 = datas[1].element.$context.parsed.y;
		const x_index =  ct1.current.scales.x.getValueForPixel(event.offsetX);
		const end_label =  ct1.current.data.labels[x_index];

	  ///console.log(end_label + ' ' + end_y_value, "  "+end_y_value2);
    setEventEndDate(end_label);
    console.log("participantProfileData",participantProfileData);
    setEventName("Positive Alcohol");
    setModalVisible(true);
    }
	}
 
}
const pointerDownOnChart3 = (event)=>{
  setChartType("Chart3");
  
  if (event.offsetX >= ct3.current.chartArea.left) {

    const datas = ct3.current.getElementsAtEventForMode(event, 'index', {
      intersect: false
    });
    if(datas.length>0){
    const start_y_value = datas[0].element.$context.parsed.y;
    const start_y_value2 = datas[1].element.$context.parsed.y;
    console.log("datas here",start_y_value2);
    const x_index = ct3.current.scales.x.getValueForPixel(event.offsetX);
    const start_label = ct3.current.data.labels[x_index];

    console.log(start_label + ' ' + start_y_value);
    setEventStartDate(start_label);
    }

  }
}


const pointerOnChart3 = (event)=>{
  setChartType("Chart3");
if (event.offsetX <=  ct3.current.chartArea.right) {

  const datas =  ct3.current.getElementsAtEventForMode(event, 'index', {
    intersect: false
  });
  if(datas.length>0){
    const end_y_value = datas[0].element.$context.parsed.y;
  const end_y_value2 = datas[1].element.$context.parsed.y;
  const x_index =  ct3.current.scales.x.getValueForPixel(event.offsetX);
  const end_label =  ct3.current.data.labels[x_index];

  console.log(end_label + ' ' + end_y_value, "  "+end_y_value2);
  setEventEndDate(end_label);

  }
  
}
console.log("participantProfileData",participantProfileData);
setEventName("Obstruction");
setModalVisible(true);
}
const pointerDownOnChart4 = (event)=>{
  setChartType("Chart4");
  if (event.offsetX >= ct4.current.chartArea.left) {

    const datas = ct4.current.getElementsAtEventForMode(event, 'index', {
      intersect: false
    });
    if(datas.length>0){
    const start_y_value = datas[0].element.$context.parsed.y;
    const start_y_value2 = datas[1].element.$context.parsed.y;
    console.log("datas here",start_y_value2);
    const x_index = ct4.current.scales.x.getValueForPixel(event.offsetX);
    const start_label = ct4.current.data.labels[x_index];

 console.log(start_label + ' ' + start_y_value);
 setEventStartDate(start_label);
    }

  }
}


const pointerOnChart4 = (event)=>{
  setChartType("Chart4");
if (event.offsetX <=  ct4.current.chartArea.right) {
   

  const datas =  ct4.current.getElementsAtEventForMode(event, 'index', {
    intersect: false
  });
  if(datas.length>0){
  const end_y_value = datas[0].element.$context.parsed.y;
  const end_y_value2 = datas[1].element.$context.parsed.y;
  const x_index =  ct4.current.scales.x.getValueForPixel(event.offsetX);
  const end_label =  ct4.current.data.labels[x_index];

  console.log(end_label + ' ' + end_y_value, "  "+end_y_value2);
  setEventEndDate(end_label);
  console.log("participantProfileData",participantProfileData);
  setEventName("Obstruction ");
  setModalVisible(true);
  }
}
}
const hoveronChart = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct1.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
    try{
    console.log(points,"ok");
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    console.log("dataset,",dataset,"datapaoint",datapoint);
    ct2.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }
  ])
   
    ct2.current.update();
    ///////////// graph 3
    ct3.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.update();
    ///////////// graph 4
    ct4.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.update();
    ///////////// graph 5
    ct5.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.update();
    ///////// graph 6
 
      ct6.current.tooltip.setActiveElements([{
        datasetIndex: 0, index:datapoint
      }])
      ct6.current.setActiveElements([{
        datasetIndex: 0, index:datapoint
      }])
      ct6.current.update();
  }catch(e){
      console.log('error heehl', ct6.current.data);        
  }
   
  }else{
    ct2.current.tooltip.setActiveElements([],{x:0,y:0})
    ct2.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct3.current.tooltip.setActiveElements([],{x:0,y:0})
    ct3.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct4.current.tooltip.setActiveElements([],{x:0,y:0})
    ct4.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct5.current.tooltip.setActiveElements([],{x:0,y:0})
    ct5.current.setActiveElements([],{x:0,y:0})
    ct6.current.tooltip.setActiveElements([],{x:0,y:0})
    ct6.current.setActiveElements([],{x:0,y:0})
  }
  //   let x = chart.tooltip._active[0].element.x;
  //   let yAxis = chart.scales.y;
  //   let ctx2 = ct2.current.ctx;
  //   ctx2.save();
  //   ctx2.beginPath();
  //   // ctx.setLineDash([5, 5]);
  //   ct2.current.tooltip._active = chart.tooltip._active;
  //   ct2.current.tooltip.update(true);
  //   ctx2.moveTo(x, yAxis.top);
  //   ctx2.lineTo(x, yAxis.bottom);
  //   ctx2.lineWidth = 2;
  //   ctx2.strokeStyle = '#1cc6bc';
  //   //ctx2.stroke();
  //   ctx2.restore();
  //   ///////////
  //   let ctx3 = ct3.current.ctx;
  //   ctx3.save();
  //   ctx3.beginPath();
  //   // ctx.setLineDash([5, 5]);
  //   ct3.current.tooltip._active = chart.tooltip._active;
  //   ct3.current.tooltip.update(true);
  //   ctx3.moveTo(x, yAxis.top);
  //   ctx3.lineTo(x, yAxis.bottom);
  //   ctx3.lineWidth = 2;
  //   ctx3.strokeStyle = '#1cc6bc';
  //   //ctx3.stroke();
  //   ctx3.restore();
  //   //////////////
  //   let ctx4 = ct4.current.ctx;
  //   ctx4.save();
  //   ctx4.beginPath();
  //   // ctx.setLineDash([5, 5]);
  //   ct4.current.tooltip._active = chart.tooltip._active;
  //   ct4.current.tooltip.update(true);
  //   ctx4.moveTo(x, yAxis.top);
  //   ctx4.lineTo(x, yAxis.bottom);
  //   ctx4.lineWidth = 2;
  //   ctx4.strokeStyle = '#1cc6bc';
  //  // ctx4.stroke();
  //   ctx4.restore();
  //   ////////////
  //   let ctx5 = ct5.current.ctx;
  //   ctx5.save();
  //   ctx5.beginPath();
  //   // ctx.setLineDash([5, 5]);
  //   ct5.current.tooltip._active = chart.tooltip._active;
  //   ct5.current.tooltip.update(true);
  //   ctx5.moveTo(x, yAxis.top);
  //   ctx5.lineTo(x, yAxis.bottom);
  //   ctx5.lineWidth = 2;
  //   ctx5.strokeStyle = '#1cc6bc';
  //   //ctx5.stroke();
  //   ctx5.restore();
  //    ////////////
  //    let ctx6 = ct6.current.ctx;
  //    ctx6.save();
  //    ctx6.beginPath();
  //    // ctx.setLineDash([5, 5]);
  //    ct6.current.tooltip._active = chart.tooltip._active;
  //   // ct6.current.tooltip.update(true);
  //    ctx6.moveTo(x, yAxis.top);
  //    ctx6.lineTo(x, yAxis.bottom);
  //    ctx6.lineWidth = 2;
  //    ctx6.strokeStyle = '#1cc6bc';
  //    //ctx6.stroke();
  //    ctx6.restore();
}
const hoveronChart2 = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct2.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
   
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    ct1.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
   ])
    ct1.current.update();
    ///////////// graph 3
    ct3.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.update();
    ///////////// graph 4
    ct4.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.update();
    ///////////// graph 5
    ct5.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.update();
    /////////// graph 6
    ct6.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.update();
  }else{
    ct1.current.tooltip.setActiveElements([],{x:0,y:0})
    ct1.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct3.current.tooltip.setActiveElements([],{x:0,y:0})
    ct3.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct4.current.tooltip.setActiveElements([],{x:0,y:0})
    ct4.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct5.current.tooltip.setActiveElements([],{x:0,y:0})
    ct5.current.setActiveElements([],{x:0,y:0})
     ////////graph 6
     ct6.current.tooltip.setActiveElements([],{x:0,y:0})
     ct6.current.setActiveElements([],{x:0,y:0})
  }
}
const hoveronChart3 = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct3.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
    console.log(points,"ok");
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    ct1.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
  ])
    ct1.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.update();
    ///////////// graph 3
    ct2.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.update();
    ///////////// graph 4
    ct4.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.update();
    ///////////// graph 5
    ct5.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.update();
    /////////// graph 6
    ct6.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.update();
  }else{
    ct1.current.tooltip.setActiveElements([],{x:0,y:0})
    ct1.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct2.current.tooltip.setActiveElements([],{x:0,y:0})
    ct2.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct4.current.tooltip.setActiveElements([],{x:0,y:0})
    ct4.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct5.current.tooltip.setActiveElements([],{x:0,y:0})
    ct5.current.setActiveElements([],{x:0,y:0})
    ct6.current.tooltip.setActiveElements([],{x:0,y:0})
    ct6.current.setActiveElements([],{x:0,y:0})
  }
}
const handleSaveData = ()=>{
  let eventId = 1;
  eventsType.forEach((el)=>{
    if(eventName == "Positive Alcohol" && el.name=="Positive Alcohol" )
    {
      eventId = el.id
    }
    if(eventName == "Obstruction" && el.name=="Obstruction Tamper" )
    {
      eventId = el.id
    }
  })
  
  let deviceId = 1;
  participantProfileData.assignedDevices.forEach((el)=>{
    if(el.device.deviceTypeName =="Watch"){
      deviceId = el.device.id
    }
  })

  const sendData = {
    eventType: {
      id: eventId
    },
    device: {
      id: deviceId
    },
    participant: {
      id: participantProfileData.id
    },
    alcoholConcentration: 1,
    receivedAt: moment(eventStartDate).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""),
    compliantAt: moment(eventEndDate).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")
  }
  apiHandler({
    url: endpoint.COMPLIANCE_EVENT,
    method: "POST",
    data: sendData,
    authToken,
  }).then(async (result) => {
    //console.log("result here",result);
    notification.success({
      description: result?.data?.message,
      placement: "topRight",
      duration: 5,
    });
    setModalVisible(false);
  });
}
const zoomModalOpen = (value) =>{
  setActiveChart(value);
  setZoomModalVisible(true);
}
const copyUrlFun = () =>{
  navigator.clipboard.writeText(copyUrl);
  setCopyModalVisible(false)
  notification.success({
    description: "URL copied to clipboard",
    placement: "topRight",
    duration: 5,
  });
}
const shareLink = () =>{
  const url = window.location.origin+'/caseload/'+participantProfileData.id+'/'+(((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) || moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) + "/" +((typeof historyEndDate === 'string' || historyEndDate instanceof String )? historyEndDate:  historyEndDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""))+"/metrics"
  setCopyUrl(url);
  setCopyModalVisible(true)

}
const hoveronChart4 = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct4.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
    console.log(points,"ok");
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    ct1.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.update();
    ///////////// graph 3
    ct3.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.update();
    ///////////// graph 4
    ct2.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.update();
    ///////////// graph 5
    ct5.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.update();
    /////////// graph 6
    ct6.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.update();
  }else{
    ct1.current.tooltip.setActiveElements([],{x:0,y:0})
    ct1.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct3.current.tooltip.setActiveElements([],{x:0,y:0})
    ct3.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct2.current.tooltip.setActiveElements([],{x:0,y:0})
    ct2.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct5.current.tooltip.setActiveElements([],{x:0,y:0})
    ct5.current.setActiveElements([],{x:0,y:0})
    ct6.current.tooltip.setActiveElements([],{x:0,y:0})
    ct6.current.setActiveElements([],{x:0,y:0})
  }
}
const hoveronChart5 = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct5.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
    console.log(points,"ok");
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    ct1.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.update();
    ///////////// graph 3
    ct3.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.update();
    ///////////// graph 4
    ct4.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.update();
    ///////////// graph 5
    ct2.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.update();
    ///////////// graph 6
    ct6.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct6.current.update();
  }else{
    ct1.current.tooltip.setActiveElements([],{x:0,y:0})
    ct1.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct3.current.tooltip.setActiveElements([],{x:0,y:0})
    ct3.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct4.current.tooltip.setActiveElements([],{x:0,y:0})
    ct4.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct2.current.tooltip.setActiveElements([],{x:0,y:0})
    ct2.current.setActiveElements([],{x:0,y:0})
    ct6.current.tooltip.setActiveElements([],{x:0,y:0})
    ct6.current.setActiveElements([],{x:0,y:0})
  }
}
const hoveronChart6 = (move)=>{
  //onsole.log("work here jdjdj",ct6)
  const points = ct6.current.getElementsAtEventForMode(move,'nearest',{intersect:true},true);
  
  if(points[0]){
    console.log(points,"ok");
    const dataset = points[0].datasetIndex;
    const datapoint = points[0].index;
    ct1.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    }])
    ct1.current.update();
    ///////////// graph 3
    ct3.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    }])
    ct3.current.update();
    ///////////// graph 4
    ct4.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct4.current.update();
    ///////////// graph 5
    ct2.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    },
    {
      datasetIndex: 2, index:datapoint
    },
    {
      datasetIndex: 3, index:datapoint
    },
    {
      datasetIndex: 4, index:datapoint
    },
    {
      datasetIndex: 5, index:datapoint
    },
    {
      datasetIndex: 6, index:datapoint
    }])
    ct2.current.update();
    ///////////// graph 6
    ct5.current.tooltip.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.setActiveElements([{
      datasetIndex: 0, index:datapoint
    },
    {
      datasetIndex: 1, index:datapoint
    }])
    ct5.current.update();
  }else{
    ct1.current.tooltip.setActiveElements([],{x:0,y:0})
    ct1.current.setActiveElements([],{x:0,y:0})
    ////////graph 3
    ct3.current.tooltip.setActiveElements([],{x:0,y:0})
    ct3.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct4.current.tooltip.setActiveElements([],{x:0,y:0})
    ct4.current.setActiveElements([],{x:0,y:0})
    ////////graph 4
    ct2.current.tooltip.setActiveElements([],{x:0,y:0})
    ct2.current.setActiveElements([],{x:0,y:0})
    ct5.current.tooltip.setActiveElements([],{x:0,y:0})
    ct5.current.setActiveElements([],{x:0,y:0})

  }
}
  const getBatteryPercentageData = (labels, chart_5_temp_data, green_line_idx) => {
    
    const result = {
      labels,
      datasets: [
        {
          data: chart_5_temp_data,
          borderColor: "#4270c3",
          backgroundColor: "#4270c3",
          lineTension: 0.3
        }
      ]
    };

    let warning_line_1 = [], warning_line_2 = [], green_line = [];
    
    labels.forEach((lbl, idx) => {
      warning_line_1.push(20);
      warning_line_2.push(20);
      if (idx === green_line_idx-1) {
        green_line.push(100);
      } else {
        green_line.push(0);
      }
    });
    
    // result.datasets.push({
    //   data: warning_line_1,
    //   borderColor: "#ffa467",
    //   backgroundColor: "#ffa467",
    //   fill: false,
    //   lineTension: 0.3,
    //   type: "line",
    //   borderDash: [20, 15]
    // });

    result.datasets.push({
      data: warning_line_2,
      borderColor: "#ee414f",
      backgroundColor: "#ee414f",
      fill: false,
      lineTension: 0.3,
      type: "line",
      borderDash: [20, 15]
    });

    /* result.datasets.push({
      type: 'bar',
      borderColor: '#1cc6bc',
      backgroundColor: "#1cc6bc",
      fill: false,
      data: green_line,
      maxBarThickness: 2,
    }); */

    return result;
  }

  const getPhoneBatteryPercentageData = (labels, chart_6_temp_data, green_line_idx) => {
   console.log("labels..........",labels,"data ",chart_6_temp_data);
    const result = {
      labels,
      datasets: [
        {
          data: chart_6_temp_data,
          borderColor: "#4270c3",
          backgroundColor: "#4270c3",
          lineTension: 0.3
        }
      ]
    };

    let green_line = [];
    
    labels.forEach((lbl, idx) => {
      if (idx === green_line_idx-1) {
        green_line.push(120);
      } else {
        green_line.push(0);
      }
    });

    /* result.datasets.push({
      type: 'bar',
      borderColor: '#1cc6bc',
      backgroundColor: "#1cc6bc",
      fill: false,
      data: green_line,
      maxBarThickness: 2,
    }); */

    return result;
  }

  return (
    <>
      <Row>
        <Col span={8}>
          {/* <h2></h2> */}
        </Col>
        <Col span={8}>{/* <h2>Events History</h2> */}</Col>
        <Col span={4}>
                    
        </Col>
        <Col span={4} className="pb-20-cus">
        <Button
          onClick={shareLink}
          type="primary">Share
        </Button>
          {csv_data&&csv_data.length > 0 && (
            <DownloadTableButton csvData={csv_data} headers={csv_header} />
          )}
        </Col>
        
      </Row>
      {/* <CustomDataTable
        position={["bottomRight"]}
        columns={columns}
        data={data}
        showPagination={true}
      /> */}
      <>
        <Row>
          <Col className="bdr-cus" span={8}>
            {show_chart_1 && chart_1_data && chart_1_data.datasets ?
            <>
            <StyledButton
               externalCss={css`
                padding: 5px;
                float: right;
              `}
               onClick={(e) => {
                setActiveChart(1);
                setZoomModalVisible(true);
               }}
             > Zoom</StyledButton>
              <Line    ref={ct1} options={option_1} data={chart_1_data} plugins = {
                [{
                  afterDraw: chart => {
                    //setct1(chart)
                    if (chart.tooltip?._active?.length) {
                     
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore();
                      //hoveronChart(chart,1);
                      chart.canvas.onmousemove = hoveronChart
                      chart.canvas.onmousedown = pointerDownOnChart
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart
                    }
                  }
                }]
              } /></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
          <Col className="bdr-cus" span={8}>
          
            {show_chart_2 && chart_2_data && chart_2_data.datasets ?
             <>
             <StyledButton
                externalCss={css`
                 padding: 5px;
                 float: right;
               `}
                onClick={(e) => {
                  setActiveChart(2);
                  setZoomModalVisible(true);
                }}
              > Zoom</StyledButton>
              <Line ref={ct2} options={option_2} data={chart_2_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart2
                    }
                  }
                }]
              }/></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
          <Col className="bdr-cus" span={8}>
            {show_chart_3 && chart_3_data && chart_3_data.datasets ?
             <>
             <StyledButton
                externalCss={css`
                 padding: 5px;
                 float: right;
               `}
                onClick={(e) => {
                  setActiveChart(3);
                  setZoomModalVisible(true);
                }}
              > Zoom</StyledButton>
              <Line  ref={ct3}  options={option_3} data={chart_3_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart3
                      chart.canvas.onmousedown = pointerDownOnChart3
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart3
                    }
                  }
                }]
              }/></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
        </Row>
        <Row>
          <Col className="bdr-cus" span={8}>
            {show_chart_4 && chart_4_data && chart_4_data.datasets ?
             <>
             <StyledButton
                externalCss={css`
                 padding: 5px;
                 float: right;
               `}
                onClick={(e) => {
                  setActiveChart(4);
                  setZoomModalVisible(true);
                }}
              > Zoom</StyledButton>
              <Line  ref={ct4}  options={option_4} data={chart_4_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart4
                      chart.canvas.onmousedown = pointerDownOnChart4
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart4
                    }
                  }
                }]
              }/></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
          <Col className="bdr-cus" span={8}>
            {show_chart_5 && chart_5_data && chart_5_data.datasets ?
             <>
             <StyledButton
                externalCss={css`
                 padding: 5px;
                 float: right;
               `}
                onClick={(e) => {
                  setActiveChart(5);
                  setZoomModalVisible(true);
                }}
              > Zoom</StyledButton>
              <Line  ref={ct5}  options={option_5} data={chart_5_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart5
                    }
                  }
                }]
              } /></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
          <Col className="bdr-cus" span={8}>
            {show_chart_6 && chart_6_data && chart_6_data.datasets ?
             <>
             <StyledButton
                externalCss={css`
                 padding: 5px;
                 float: right;
               `}
                onClick={(e) => {
                  setActiveChart(6);
                  setZoomModalVisible(true);
                }}
              > Zoom</StyledButton>
              <Line  ref={ct6} options={option_6} data={chart_6_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart6
                    }
                  }
                }]
              } /></>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </Col>
        </Row>
      </>
      <StyledModal visible={modalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Save Events</Col>
            <Col md={15}>
              <Button type="primary" onClick={handleSaveData} style={{float: 'right',marginLeft:'1rem'}}>
                Save
              </Button>
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
            The Selected Data will create an Event For
          </Col>
          {/* <Col md={24} className="cs_table">
            <Table dataSource={dataSource} columns={columns} pagination={false} className="cs_check" />
          </Col> */}
          
        </Row>
        <Row>
          <Col>
          {eventName}
          </Col>
        </Row>
         <Row>
          <Col>
          Participant: {participantProfileData.fullName}
          </Col>
        </Row>
        <Row>
          <Col>
          From: {eventStartDate}
          </Col>
        </Row>
        <Row>
          <Col>
          To: {eventEndDate}
          </Col>
        </Row>

       
      </StyledModal>
      <StyledModal visible={copyModalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Copy Share Url</Col>
            <Col md={15}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
          <InputAntd
              inputWidth="650px"
              label="Copy Share Url"
              value={copyUrl}
            />
            <Button
          onClick={copyUrlFun}
          type="primary">Copy Link
        </Button>
          </Col>
        </Row>
       
      </StyledModal>
      <StyledModal visible={zoomModalVisible} width={`80%`} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Chart Zoom</Col>
            <Col md={15}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        
        
          {activeChart === 1 ?
         <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_1 && chart_1_data && chart_1_data.datasets ?
              <Line    ref={ct1} options={option_1} data={chart_1_data} plugins = {
                [{
                  afterDraw: chart => {
                    //setct1(chart)
                    if (chart.tooltip?._active?.length) {
                     
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore();
                      //hoveronChart(chart,1);
                      chart.canvas.onmousemove = hoveronChart
                      chart.canvas.onmousedown = pointerDownOnChart
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart
                    }
                  }
                }]
              } />
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </div>
          :(<></>)}
           {activeChart === 2 ?
           
           <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_2 && chart_2_data && chart_2_data.datasets ?
              <Line ref={ct2} options={option_2} data={chart_2_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart2
                    }
                  }
                }]
              }/>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </div>
           :(<></>)}
          {activeChart === 3 ?
           
           <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_3 && chart_3_data && chart_3_data.datasets ?
              <Line  ref={ct3}  options={option_3} data={chart_3_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart3
                      chart.canvas.onmousedown = pointerDownOnChart3
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart3
                    }
                  }
                }]
              }/>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </div>
           :(<></>)}
          {activeChart === 4 ?
          
          <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_4 && chart_4_data && chart_4_data.datasets ?
              <Line  ref={ct4}  options={option_4} data={chart_4_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart4
                      chart.canvas.onmousedown = pointerDownOnChart4
                      // chart.canvas.onpointermove = ponterUpOnChart 
                      chart.canvas.onmouseup = pointerOnChart4
                    }
                  }
                }]
              }/>
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
          </div>
          :(<></>)}
          {activeChart === 5 ?
         
         <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_5 && chart_5_data && chart_5_data.datasets ?
              <Line  ref={ct5}  options={option_5} data={chart_5_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart5
                    }
                  }
                }]
              } />
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
         </div>
          :(<></>)}
          {activeChart === 6 ?
          
          <div style={{height:'700px',width:'900px', alignItems:"center"}}>
            {show_chart_6 && chart_6_data && chart_6_data.datasets ?
              <Line  ref={ct6} options={option_6} data={chart_6_data} plugins = {
                [{
                  afterDraw: chart => {
                    if (chart.tooltip?._active?.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      // ctx.setLineDash([5, 5]);
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = '#1cc6bc';
                      ctx.stroke();
                      ctx.restore(); 
                      chart.canvas.onmousemove = hoveronChart6
                    }
                  }
                }]
              } />
            :(<div className="chart-empty-con">
                No Data for Chart
              </div>)}
         </div>
           :(<></>)}
   

       
       
      </StyledModal>
    </>
  );
};

export default MetricsChart;
