import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import moment from "moment";
import { useEffect, useState } from "react";
import apiHandler from "api";
import { useSelector } from "react-redux";
import endpoint from "api/endpoint";
import DownloadTableButton from "components/DownloadTableButton/DownloadTableButton";
import { Col, Row,notification,Modal,Button,Table} from "antd";
import axios from 'axios';
import styled from "styled-components";
import Logo from "assets/logo.png";
import InputAntd from "components/InputAntd/InputAntd";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

import {
  StyledColLeft,
  StyledColRight,
  StyledHeading,
  StyledSubHeading,
  ActiveDevicesHeading,
  DeviceList
} from "./Breath.styles";
export const StyledModal = styled(Modal)`
  border-radius: 10px;
`;


const BreathalyzerTestHistory = () => {
  const columns = [
    {
      title: "Test Type",
      dataIndex: "testType",
    },
    {
      title: "Device",
      dataIndex: "device",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Scheduled At",
      dataIndex: "scheduled",
    },
    // {
    //   title: "Taken At/ Received At",
    //   dataIndex: "receivedAt",
    //   //sorter: (a, b) =>new Date(b.receivedDate) -
    //   //new Date(a.receivedDate),
    // },
    {
      title: "Alcohol Reading",
      dataIndex: "outcome",
      // sorter: (a, b) =>new Date(a.outcome) -
      // new Date(b.outcome),
    },
    {
      title: "Photo",
      dataIndex: "photo",
    },
    
    
  ];
  const [activeMarker, setActiveMarker] = useState(null);
  const [centerData,setCenterData] = useState({});
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  };

  const [testResultModal, settestResultModal] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [copyUrl, setCopyUrl] = useState("");
  const [photoValidate, setPhotoValidate] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [dt,setDt] = useState(false);
  const handleCancel = () => {
    setCopyModalVisible(false);
    settestResultModal(false);
  };
  const [testImage, setTestImag] = useState('');
  const [refImage, setRefImag] = useState('')
  const csvHeaders = [
    {
      label: "Test Type",
      key: "testType",
    },
    {
      label: "Device",
      key: "device",
    },
    {
      label: "Status",
      key: "status",
    },
    {
      label: "Scheduled At",
      key: "scheduled",
    },
    {
      label: "Taken At/ Received At",
      key: "receivedAt",
    },
    {
      label: "Alcohol Reading",
      key: "outcome",
    },
    
  ];

  const { historyStartDate,historyEndDate } = useSelector((state) => state.common);

  const { authToken } = useSelector((state) => state.login);

  const [BreathalyzerTestHistoryData, setBreathalyzerTestHistoryData] =
    useState();

  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

   const CheckImage = (path) => {
    axios
      .get(path)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  useEffect(() => {
    if (historyStartDate) {
    apiHandler({
      url:
        endpoint.ENROLL_PARTICIPANT +
        "/" +
        participantProfileData?.id +
        "/testResults/" +
        (historyStartDate.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")),
      authToken,
    }).then((result) => {
      /* result.data.sort(function(a, b) {
        var keyA = new Date(a.receivedAt),
          keyB = new Date(b.receivedAt);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      }); */
      result.data.forEach(obj => {
        try {
          obj.recDate = new Date(obj.receivedAt);
          obj.schDate = new Date(obj.scheduledAt);
        } catch(e){}
      });
      result.data.sort((a, b) => b.schDate - a.schDate || b.recDate - a.recDate );
      setBreathalyzerTestHistoryData(result.data);
    });
  }
  }, [historyStartDate]);

  const data = [];
  const csvData = [];
  const copyUrlFun = () =>{
    navigator.clipboard.writeText(copyUrl);
    setCopyModalVisible(false)
    notification.success({
      description: "URL copied to clipboard",
      placement: "topRight",
      duration: 5,
    });
  }
  const clickOnRow= (value) =>{
    console.log("work here",value);
  }
  const shareLink = () =>{
    const url = window.location.origin+'/caseload/'+participantProfileData.id+'/'+(((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DD")) || moment().format("YYYY-MM-DD")) + "/" +((typeof historyEndDate === 'string' || historyEndDate instanceof String )? historyEndDate:  historyEndDate.format("YYYY-MM-DD"))+"/breath"
    setCopyUrl(url);
    setCopyModalVisible(true)
  
  }
  BreathalyzerTestHistoryData?.map((obj, index) => {
    console.log(obj)
    const imageUrl = `${process.env.REACT_APP_PARTICIPANT_IMAGE_URL_QA}/${participantProfileData?.id}/testResult_${obj.id}.png`; //obj.id_thumb
    const dt =CheckImage(imageUrl);
    data.push({
      key: index,
      testType: obj.testType.name,
      scheduled: (
        <>
          <p>{obj.scheduledAt && moment(obj.scheduledAt).format("MMM D, YYYY h:mm A z")}</p>
        </>
      ),
      scheduledDate: moment(obj.scheduledAt).format("MM/DD/YYYY hh:mm A"),
      receivedDate: obj.receivedAt,
      receivedAt: (
        <>
          <p>
            {obj.receivedAt &&
            moment(obj.receivedAt).format("MM/DD/YYYY hh:mm A")
              }
          </p>
        </>
      ),
      outcome: obj.bac,
      status: obj.currentState,
      device: obj?.device?.deviceTypeName,
      id: obj.id,
      participantId: obj.participantId,
      photo : (
        <>{dt && <img alt="photo_thumb" src={imageUrl}/>}
        </>),
        testDate: moment(obj.testDate).format("MMM D, YYYY h:mm A z"),
    });
    data.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.testDate) - new Date(a.testDate);
    });
    csvData.push({
      testType: obj.testType.name,
      scheduled: moment(obj.scheduledAt).format("MM/DD/YYYY hh:mm A"),
      receivedAt:
        obj.receivedAt && moment(obj.receivedAt).format("MM/DD/YYYY hh:mm A"),
      outcome: !obj.violation && "Missed",
      status: obj.currentState,
      device: obj.device.deviceTypeName,
    });
  });

  return (
    <>
      <Row>
        <Col span={8}>
          <h2>TRAC Breath Test History</h2>
        </Col>
        <Col span={12}>{/* <h2>Events History</h2> */}</Col>
        <Col span={4}>
        <Button
          onClick={shareLink}
          type="primary">Share
        </Button>
          <DownloadTableButton csvData={csvData} headers={csvHeaders} />
        </Col>
      </Row>
      <Table
        position={["bottomRight"]}
        columns={columns}
        dataSource={data}
        showPagination={true}
       // onClick = {clickOnRow}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              console.log(record,"work target");
              settestResultModal(true);
              BreathalyzerTestHistoryData.forEach(el=>{
                if(record.id == el.id){
                  setCenterData({
                    lat: el.latitude,
                    lng: el.longitude
                  })
                  setPhotoValidate(el);
                  apiHandler({
                    url: `${endpoint.ENROLL_PARTICIPANT}`+"/"+`${el.participantId}`,
                    authToken,
                  }).then((results) => {
                   
                   setParticipantData(results.data);
                   const imageUrl = `${process.env.REACT_APP_PARTICIPANT_IMAGE_URL_QA}/${results.data?.id}/testResult_${el.id}.png`; //obj.id_thumb
                   const refimageUrl = `${process.env.REACT_APP_PARTICIPANT_IMAGE_URL_QA}/${results.data?.id}/referenceImage.png`; 
                   setRefImag(refimageUrl); 
                   setDt(CheckImage(imageUrl));
                    setTestImag(imageUrl);
                  });
                }
              })
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row

          };
        }}
      />
      <StyledModal visible={testResultModal} width={1000} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={12} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Test Result Details</Col>
            <Col md={12}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
        Test #{photoValidate.id} for {participantData.fullName} taken at {photoValidate.takenAt}
        <hr />
        <Row>
      
        </Row>
        <Row>
        <StyledColLeft md={11}>
          <br />
          <br />
          <Row><h4><b>Automated Validation Results</b></h4></Row>
          <Row>
            <Col md={12}>
            Facial Recognition:
            </Col>
            <Col style={{float:"right"}} md={8}>
            
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Time Validation: 
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedTimeValidationResult}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Liveness Detection:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedLivenessDetectionResult}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            Tamper Detection:
            </Col>
            
            <Col style={{float:"right"}} md={10}>
            {photoValidate.automatedTamperDetectionResult}
            </Col>
          </Row>
          <Row>
            <Col  md={12}>
            Confirmation Code:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.visualConfirmationCode}
            </Col>
          </Row>
          <Row><h4><b>Test Details</b></h4></Row>
          <Row>
            <Col  md={12}>
            Current State:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.currentState}
            </Col>
          </Row>
          <Row>
            <Col  md={12}>
            Device:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate?.device?.deviceTypeName}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Scheduled At:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.scheduledAt}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Taken At:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.takenAt}
            </Col>
          </Row><Row>
            <Col  md={12}>
            BAC:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.bac}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Device Battery Level:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.deviceBatteryLevel}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Phone Battery Level:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.phoneBatteryLevel}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Uploaded At:
            </Col>
            <Col style={{float:"right"}} md={10}>
           
            </Col>
          </Row><Row>
            <Col  md={12}>
            App Version:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.appBuild}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Os Version:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.osVersion}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Phone Model :
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.phoneModel}
            </Col>
          </Row><Row>
            <Col  md={12}>
            Firmware Version:
            </Col>
            <Col style={{float:"right"}} md={10}>
            {photoValidate.firmwareVersion}
            </Col>
          </Row>
        </StyledColLeft>
        <StyledColRight md={13}>
          <Row>
            <Col  md={10}>
              <center>Refrence Photo</center>
              
              {<img  width ="90%" alt="photo_thumb" src={refImage}/>}
            {/* <img width ="90%" src={TestPhoto} /> */}
            </Col>
            <Col  md={10}>
            <center>Test Photo</center>
            {dt && <img width ="90%" src={testImage} />}
            </Col>
          </Row>
          <Row>
          <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            center={centerData}
            zoom={4}
            mapContainerStyle={{ width: "100vw", height: "50vh" }}
          >

        <Marker
          //key={id}
          position={centerData}
         // onClick={() => handleActiveMarker(id)}
        >
        </Marker>
    </GoogleMap>
          </Row>
          </StyledColRight>
        </Row>
        </Row>
       
      </StyledModal>
      <StyledModal visible={copyModalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Copy Share Url</Col>
            <Col md={15}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
          <InputAntd
              inputWidth="650px"
              label="Copy Share Url"
              value={copyUrl}
            />
            <Button
          onClick={copyUrlFun}
          type="primary">Copy Link
        </Button>
          </Col>
        </Row>
       
      </StyledModal>
    </>
  );
};

export default BreathalyzerTestHistory;
